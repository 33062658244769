import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { HttpClientModule } from '@angular/common/http';
import { NgCoreConfig } from '@labshare/ng-core';
import { routerConfig } from './routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FacilitiesModule } from './facilities/facilities.module';
import { AppComponent } from './app.component';
import { UtilService } from '@labshare/ngx-labshare-base';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FacilitiesModule,
    UIRouterModule.forChild(routerConfig)
  ],
  declarations: [AppComponent],
  entryComponents: [],
  exports: [],
  providers: [NgCoreConfig, UtilService],
  bootstrap: []
})
export class AppModule {}
