import { Component, OnInit } from '@angular/core';
import { Tenant, Group, User } from '@labshare/ngx-labshare-base';
import { UIRouter } from '@uirouter/core';
import { FacilityService } from '../facility.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupManageComponent } from '../group-manage/group-manage.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-group-users',
  template: require('./group-users.component.html'),
  styles: [require('./group-users.component.scss').toString()]
})
export class GroupUsersComponent implements OnInit {
  facility: Tenant = {
    id: '',
    title: ''
  };
  selectedGroup: Group = {
    id: null,
    name: '',
    description: ''
  };
  users: User[];
  usersToSearch: User[];
  userToDelete = '';
  filter = new FormControl('');
  settingsModalRef: NgbModalRef;
  deleteModalRef: NgbModalRef;

  constructor(
    private uiRouter: UIRouter,
    private facilityService: FacilityService,
    private modalService: NgbModal
  ) {}

  /**
   * @todo look into combining the api calls with an operator or better state management
   */
  ngOnInit() {
    this.facilityService
      .getFacility(this.uiRouter.stateService.params.facilityId)
      .subscribe(data => {
        this.facility = data;
      });

    this.getGroup();

    this.getUsers();
  }

  getGroup() {
    this.facilityService
      .getGroup(
        this.uiRouter.stateService.params.facilityId,
        this.uiRouter.stateService.params.groupName
      )
      .subscribe(data => {
        this.selectedGroup = data;
      });
  }

  getUsers() {
    this.facilityService
      .getUsersForGroup(
        this.uiRouter.stateService.params.facilityId,
        this.uiRouter.stateService.params.groupName
      )
      .subscribe(data => {
        this.users = data;
        this.usersToSearch = data;
      });
  }

  search() {
    this.users = this.usersToSearch.filter(user => {
      const term = this.filter.value.toLowerCase();
      return (
        user.firstName.toLowerCase().includes(term) ||
        user.lastName.toLowerCase().includes(term) ||
        user.email.toLowerCase().includes(term)
      );
    });
  }

  updateSettings() {
    this.settingsModalRef = this.modalService.open(GroupManageComponent);
    this.settingsModalRef.componentInstance.facilityId = this.uiRouter.stateService.params.facilityId;
    this.settingsModalRef.componentInstance.group = this.selectedGroup;
    this.settingsModalRef.result.then(
      groupName => {
        if (
          groupName &&
          groupName !== this.uiRouter.stateService.params.groupName
        ) {
          this.uiRouter.stateService.go(
            this.uiRouter.stateService.current.name,
            {
              facilityId: this.uiRouter.stateService.params.facilityId,
              groupName: groupName
            }
          );
        } else {
          this.getGroup();
          this.getUsers();
        }
      },
      () => null
    );
  }

  deleteUser(deleteModal, userEmail: string) {
    this.userToDelete = userEmail;
    this.deleteModalRef = this.modalService.open(deleteModal);
    this.deleteModalRef.result.then(
      () => {
        this.facilityService
          .deleteUser(this.uiRouter.stateService.params.facilityId, userEmail)
          .subscribe({
            complete: () => {
              this.getUsers();
            }
          });
      },
      () => null
    );
  }
}
