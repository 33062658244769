import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FacilityService } from '../facility.service';
import { UtilService, Tenant } from '@labshare/ngx-labshare-base';
import { UIRouter } from '@uirouter/core';
import { FIND_WORKSPACE_ROUTE_NAME, PAGES_ROUTE_NAME } from '../constants';

@Component({
  selector: 'app-access-workspace',
  template: require('./access-workspace.component.html'),
  styles: [require('./access-workspace.component.scss').toString()]
})
export class AccessWorkspaceComponent implements OnInit {
  accessForm = this.fb.group({
    id: this.fb.control('', [Validators.required, Validators.required])
  });
  showErrorMessage = false;
  findWorkspaceRoute = FIND_WORKSPACE_ROUTE_NAME;
  foundFacility: Tenant[];

  constructor(
    private fb: FormBuilder,
    private facilityService: FacilityService,
    private utilService: UtilService,
    private uiRouter: UIRouter
  ) {}

  ngOnInit() {}

  redirectToFacility() {
    this.facilityService
      .getFacilitiesForUser(this.utilService.getLoggedInUserProfile().email)
      .subscribe(facilities => {
        this.foundFacility = facilities
          ? facilities.filter(f => f.id === this.accessForm.value.id)
          : [];
        if (this.foundFacility.length === 1) {
          this.uiRouter.stateService.go(PAGES_ROUTE_NAME, {
            facilityId: this.foundFacility[0].id
          });
        } else {
          this.showErrorMessage = true;
          this.accessForm.setValue({ id: '' });
        }
      });
  }
}
