import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Group } from '@labshare/ngx-labshare-base';
import { GROUP_FORM_EDIT, GROUP_FORM_ADD } from '../constants';

@Component({
  selector: 'app-group-form',
  template: require('./group-form.component.html'),
  styles: [require('./group-form.component.scss').toString()]
})
export class GroupFormComponent implements OnInit {
  @Input() facilityId: string;
  @Input() group: Group;
  @Output() valid = new EventEmitter<boolean>();
  title: string;

  groupManageForm = this.fb.group({
    name: this.fb.control('', [Validators.required]),
    description: this.fb.control('')
  });

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.outputTitle();
  }

  outputTitle() {
    if (this.group) {
      this.title = GROUP_FORM_EDIT;
    } else {
      this.title = GROUP_FORM_ADD;
    }
  }

  checkValidity() {
    this.valid.emit(this.groupManageForm.valid);
  }
}
