import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { Tenant, Group } from '@labshare/ngx-labshare-base';
import {
  NgbActiveModal,
  NgbTabChangeEvent,
  NgbTabset
} from '@ng-bootstrap/ng-bootstrap';
import { GroupFormComponent } from '../group-form/group-form.component';
import { UserManageComponent } from '../user-manage/user-manage.component';
import { FacilityService } from '../facility.service';
import { mergeMap, take } from 'rxjs/operators';
import { from, Subscription, of } from 'rxjs';

@Component({
  selector: 'app-group-manage',
  template: require('./group-manage.component.html'),
  styles: [require('./group-manage.component.scss').toString()]
})
export class GroupManageComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription;
  @Input() facilityId: string;
  @Input() group: Group;
  validGroupForm: boolean;

  @ViewChild(GroupFormComponent) groupFormComponent: GroupFormComponent;
  @ViewChild(NgbTabset) groupUserTabSet: NgbTabset;
  @ViewChild(UserManageComponent) userManageComponent: UserManageComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private facilityService: FacilityService
  ) {}

  ngOnInit() {
    this.subscription = new Subscription();
  }

  ngAfterViewInit() {
    this.getGroupToEdit();
  }

  getGroupToEdit() {
    /** reason for setTimeout.  https://blog.angular-university.io/angular-debugging/ */
    if (this.group) {
      setTimeout(() => {
        this.groupFormComponent.groupManageForm.setValue({
          name: this.group.name,
          description: this.group.description
        });
        this.groupFormComponent.checkValidity();
      });
    }
  }

  onGroupFormValid(valid: boolean) {
    this.validGroupForm = valid;
  }

  save() {
    const users = this.userManageComponent.users;
    const source = this.facilityService
      .saveGroup(
        this.facilityId,
        this.groupFormComponent.groupManageForm.value,
        this.group ? this.group.name : undefined
      )
      .pipe(
        mergeMap(group => {
          return from(users).pipe(
            mergeMap(user => {
              const fullUser = {
                ...user,
                facilityId: this.facilityId
              };
              return this.facilityService.addFacilityUser(fullUser).pipe(
                mergeMap(() => {
                  return this.facilityService.assignUserToGroup(
                    this.facilityId,
                    group.name,
                    user.email
                  );
                })
              );
            })
          );
        })
      );

    this.subscription = source.subscribe({
      complete: () => {
        this.activeModal.close(
          this.groupFormComponent.groupManageForm.value['name']
        );
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
