import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { FacilityService } from '../facility.service';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { Group } from '@labshare/ngx-labshare-base';
import { mergeMap, tap } from 'rxjs/operators';
import { Page } from '../types';
import { PAGE_MANAGE_EDIT, PAGE_MANAGE_ADD } from '../constants';

@Component({
  selector: 'app-page-manage',
  template: require('./page-manage.component.html'),
  styles: [require('./page-manage.component.scss').toString()]
})
export class PageManageComponent implements OnInit {
  title = '';
  facilityId = '';
  pageId: string;
  pageManageForm: FormGroup;
  groups: FormArray;
  groupsData: Group[];

  constructor(
    private uiRouter: UIRouter,
    private facilityService: FacilityService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.pageManageForm = this.fb.group({
      title: this.fb.control('', [Validators.required]),
      body: this.fb.control(''),
      groups: this.fb.array([])
    });

    this.facilityId = this.uiRouter.stateService.params.facilityId;
    this.pageId = this.uiRouter.stateService.params.pageId
      ? this.uiRouter.stateService.params.pageId
      : undefined;
    this.getTitle();
    this.getExistingPage();
    if (!this.pageId) {
      this.getGroups(undefined).subscribe();
    }
  }

  getTitle() {
    if (this.pageId) {
      this.title = PAGE_MANAGE_EDIT;
    } else {
      this.title = PAGE_MANAGE_ADD;
    }
  }

  getExistingPage() {
    if (this.pageId) {
      this.facilityService
        .getPage(this.facilityId, this.pageId)
        .pipe(
          mergeMap(page => {
            this.pageManageForm.controls['title'].setValue(page.title);
            this.pageManageForm.controls['body'].setValue(page.body);
            return this.getGroups(page);
          })
        )
        .subscribe();
    }
  }

  getGroups(page: Page) {
    let controls;

    return this.facilityService.getGroupsForFacility(this.facilityId).pipe(
      tap(groups => {
        this.groups = this.pageManageForm.get('groups') as FormArray;
        this.groupsData = groups;
        controls = groups.map(g => {
          let selected = false;

          if (page && page.groups) {
          }

          if (
            page &&
            page.groups &&
            page.groups.filter(pg => pg === g.name).length > 0
          ) {
            selected = true;
          }
          const control = this.fb.control(selected);
          return control;
        });
        this.groups.controls = controls;
      })
    );
  }

  save() {
    const selectedGroups = this.groups.controls
      .map((c, index) => {
        if (c.value) {
          return this.groupsData[index].name;
        }
      })
      .filter(g => g !== undefined);

    const page = {
      ...this.pageManageForm.value,
      ...{ groups: selectedGroups }
    };

    this.facilityService
      .savePage(this.facilityId, this.pageId, page)
      .subscribe({
        complete: () => {
          this.uiRouter.stateService.go('facility.pages', {
            facilityId: this.facilityId
          });
        }
      });
  }
}
