/** main module for the facility creation screens */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

import { routerConfig } from './facilities.routes';
import {
  WelcomeComponent,
  ProfileComponent,
  CreateTeamMembersComponent,
  CreateTenantComponent,
  IdentityProviderSelectionComponent,
  ReviewTeamWorkspaceComponent
} from '@labshare/ngx-labshare-base';
import { FacilitiesComponent } from './facilities/facilities.component';
import { HttpClientModule } from '@angular/common/http';
import { FacilityHomeComponent } from './facility-home/facility-home.component';
import { ReviewTeamWorkspaceWrapperComponent } from './review-team-workspace-wrapper/review-team-workspace-wrapper.component';
import { GroupListComponent } from './group-list/group-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GroupFormComponent } from './group-form/group-form.component';
import { GroupManageComponent } from './group-manage/group-manage.component';
import { UserManageComponent } from './user-manage/user-manage.component';
import { GroupUsersComponent } from './group-users/group-users.component';
import { PagesListComponent } from './pages-list/pages-list.component';
import { PageManageComponent } from './page-manage/page-manage.component';
import {
  LeftNavModule,
  FitWindowModule,
  TopNavModule
} from '@labshare/stateful-components';
import { TreeviewModule } from '@labshare/ngx-storage/ui/treeview/treeview.module';
import { NavbarModule } from '@labshare/ngx-storage/ui/navbar/navbar.module';
import { FileManagerModule } from '@labshare/ngx-storage/ui/file-manager/file-manager.module';
import { GraphContainerModule } from '@labshare/ngx-storage/ui/graph-container/graph-container.module';
import { AccessWorkspaceComponent } from './access-workspace/access-workspace.component';
import { FindWorkspaceComponent } from './find-workspace/find-workspace.component';
import { CreateWorkspaceComponent } from './create-workspace/create-workspace.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild(routerConfig),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    QuillModule,
    LeftNavModule,
    TopNavModule,
    FitWindowModule,
    TreeviewModule,
    NavbarModule,
    FileManagerModule,
    GraphContainerModule,
    FileUploadModule,
    BrowserAnimationsModule
  ],
  declarations: [
    FacilitiesComponent,
    ProfileComponent,
    CreateTenantComponent,
    CreateTeamMembersComponent,
    ReviewTeamWorkspaceComponent,
    ReviewTeamWorkspaceWrapperComponent,
    IdentityProviderSelectionComponent,
    FacilityHomeComponent,
    WelcomeComponent,
    GroupListComponent,
    GroupFormComponent,
    GroupManageComponent,
    UserManageComponent,
    GroupUsersComponent,
    PagesListComponent,
    PageManageComponent,
    AccessWorkspaceComponent,
    FindWorkspaceComponent,
    CreateWorkspaceComponent
  ],
  entryComponents: [GroupManageComponent]
})
export class FacilitiesModule {}
