import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilService } from '@labshare/ngx-labshare-base';
import { FacilityService } from '../facility.service';
import { mergeMap, take } from 'rxjs/operators';
import { from, Subscription, scheduled, asapScheduler } from 'rxjs';
import { UIRouter } from '@uirouter/core';
import {
  PAGES_ROUTE_NAME,
  FILE_EXTENSION_PNG,
  BASE_64_BEGIN,
  FILE_EXTENSION_JPG,
  CREATE_TEAM_MEMBERS_ROUTE_NAME
} from '../constants';
import { FILE_TYPE_JPEG } from '@labshare/ngx-labshare-base/src/app/shared/tenant-creation-process-constants';

@Component({
  selector: 'app-review-team-workspace-wrapper',
  template: require('./review-team-workspace-wrapper.component.html'),
  styles: [require('./review-team-workspace-wrapper.component.css').toString()]
})
export class ReviewTeamWorkspaceWrapperComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  disableFinishButton = false;
  childComponentBasicInfo = {
    previousRoute: CREATE_TEAM_MEMBERS_ROUTE_NAME
  };

  constructor(
    private utilService: UtilService,
    private facilityService: FacilityService,
    private uiRouter: UIRouter
  ) {}

  ngOnInit() {
    this.subscription = new Subscription();
  }

  /**
   * @todo add error handling for observables
   * @todo remove final facility session storage - will come from APIs
   */
  onDone(finalData: any) {
    let base64ToFileLogo: File;
    let idProviders: Array<string> = [];
    const allUsers = [...finalData.users, finalData.profileInfo];

    finalData.identityProviderInfo.forEach(idp => {
      idProviders = [...idProviders, idp.id];
    });

    finalData.tenantInfo.idProviders = idProviders;

    // retrive the logo from local storage and convert back to File type
    if (finalData.logo) {
      let fileExtension = FILE_EXTENSION_PNG;
      if (finalData.logo.indexOf(BASE_64_BEGIN + FILE_TYPE_JPEG) >= 0) {
        fileExtension = FILE_EXTENSION_JPG;
      }
      const fileName = finalData.tenantInfo.id + fileExtension;
      base64ToFileLogo = this.utilService.dataURLtoFile(
        finalData.logo,
        fileName
      );
    }

    if (finalData.tenantInfo.id) {
      this.disableFinishButton = true;
      const source = this.facilityService
        .addFacility(finalData.tenantInfo)
        .pipe(
          mergeMap(facility =>
            from(allUsers).pipe(
              mergeMap(user => {
                const fullUser = {
                  ...user,
                  facilityId: facility.id
                };
                return this.facilityService.addFacilityUser(fullUser).pipe(
                  mergeMap(() => {
                    return this.facilityService
                      .assignUserToGroup(facility.id, user.role, user.email)
                      .pipe(take(1));
                  })
                );
              })
            )
          )
        )
        .pipe(
          mergeMap(() => {
            if (base64ToFileLogo) {
              return this.facilityService
                .saveLogo(finalData.tenantInfo.id, base64ToFileLogo)
                .pipe(take(1));
            }
            return scheduled([], asapScheduler);
          })
        );

      this.subscription = source.subscribe({
        complete: () => {
          this.disableFinishButton = false;
          this.uiRouter.stateService.go(PAGES_ROUTE_NAME, {
            facilityId: finalData.tenantInfo.id
          });
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
