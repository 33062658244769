/** main client routes file, the routes are split up by module. ie. /facilities/facilities.routes for facility creation screens */

export const routerConfig = {
  states: [
    {
      name: 'root',
      url: '/',
      redirectTo: 'facilities.welcome'
    }
  ],
  useHash: false
};
