import { Component, OnInit } from '@angular/core';
import { UtilService, Tenant } from '@labshare/ngx-labshare-base';
import { FacilityService } from '../facility.service';
import { UIRouter } from '@uirouter/core';
import { PAGES_ROUTE_NAME } from '../constants';

@Component({
  selector: 'app-find-workspace',
  template: require('./find-workspace.component.html'),
  styles: [require('./find-workspace.component.scss').toString()]
})
export class FindWorkspaceComponent implements OnInit {
  facilities: Tenant[];
  email: string;
  showMessage = false;
  currentFacilityId: string;

  constructor(
    private facilityService: FacilityService,
    private utilService: UtilService,
    private uiRouter: UIRouter
  ) {}

  ngOnInit() {
    this.email = this.utilService.getLoggedInUserProfile().email;
    this.facilityService.getFacilitiesForUser(this.email).subscribe(data => {
      this.facilities = data ? data : [];
      if (this.facilities.length === 0) {
        this.showMessage = true;
      }
    });
  }

  accessFacility(id: string) {
    this.currentFacilityId = id;
    this.uiRouter.stateService.go(PAGES_ROUTE_NAME, {
      facilityId: id
    });
  }
}
