import { Component, OnInit } from '@angular/core';
import { Tenant } from '@labshare/ngx-labshare-base';
import { Page } from '../types';
import { UIRouter } from '@uirouter/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FacilityService } from '../facility.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pages-list',
  template: require('./pages-list.component.html'),
  styles: [require('./pages-list.component.scss').toString()]
})
export class PagesListComponent implements OnInit {
  facility: Tenant = {
    id: '',
    title: ''
  };
  pages: Page[];
  pagesToSearch: Page[];
  pageToDelete = '';
  filter = new FormControl('');
  deleteModalRef: NgbModalRef;

  constructor(
    private uiRouter: UIRouter,
    private facilityService: FacilityService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.facilityService
      .getFacility(this.uiRouter.stateService.params.facilityId)
      .subscribe(data => {
        this.facility = data;
      });

    this.getPages();
  }

  getPages() {
    this.facilityService
      .getPages(this.uiRouter.stateService.params.facilityId)
      .subscribe(data => {
        this.pages = data;
        this.pagesToSearch = data;
      });
  }

  search() {
    this.pages = this.pagesToSearch.filter(page => {
      const term = this.filter.value.toLowerCase();
      return (
        page.title.toLowerCase().includes(term) ||
        page.createdBy.toLowerCase().includes(term)
      );
    });
  }

  deletePage(deleteModal, pageId: string, pageTitle: string) {
    this.pageToDelete = pageTitle;
    this.deleteModalRef = this.modalService.open(deleteModal);
    this.deleteModalRef.result.then(
      () => {
        this.facilityService
          .deletePage(this.uiRouter.stateService.params.facilityId, pageId)
          .subscribe({
            complete: () => {
              this.getPages();
            }
          });
      },
      () => null
    );
  }
}
