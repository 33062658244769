import { Component, Input } from '@angular/core';
import { PROFILE_ROUTE_NAME } from '../constants';

@Component({
  selector: 'app-create-workspace',
  template: require('./create-workspace.component.html'),
  styles: [require('./create-workspace.component.scss').toString()]
})
export class CreateWorkspaceComponent {
  @Input() includeOr = false;
  profileRoute = PROFILE_ROUTE_NAME;
}
