import 'hammerjs';

import { ngCore } from '@labshare/ng-core';
import { ILocationProvider } from 'angular';

/**
 * @todo organize css better
 * @todo temporarily removed html5 routing option because of the problem with auth redirection;
 *        was redirecting to /undefined after authenticating
 *        once the ngcore dependecy is removed, will try to make it work with html5 routing
 * @todo add input validation
 */

/**
ngCore.config([
  '$locationProvider',
  ($locationProvider: ILocationProvider) => {
    $locationProvider.html5Mode(true);
  }
]);
 */

export { AppModule } from './app/app.module';
