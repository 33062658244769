/** constant variables to avoid magic strings */

/** API URLs */
export const FACILITIES_URL = '/facilities';
export const USERS_URL = '/users';
export const GROUPS_URL = '/groups';
export const PAGES_URL = '/pages';
export const REL_URL = '/rel';
export const PROVIDER_URL = '/idproviders';
export const LOGO_URL = '/logo';

/** titles */
export const GROUP_FORM_EDIT = 'Edit Group Settings';
export const GROUP_FORM_ADD = 'Create Your Custom Group';
export const PAGE_MANAGE_EDIT = 'Edit Page';
export const PAGE_MANAGE_ADD = 'Add New Page';

/** routes */
export const FACILITIES_ROOT_ROUTE_NAME = 'facilities';
export const FACILITIES_ROOT_ROUTE_URL = '/facilities';
export const WELCOME_ROUTE_NAME = 'facilities.welcome';
export const WELCOME_ROUTE_URL = '/welcome';
export const ACCESS_WORKSPACE_ROUTE_NAME = 'facilities.accessWorkspace';
export const ACCESS_WORKSPACE_ROUTE_URL = '/access-workspace';
export const FIND_WORKSPACE_ROUTE_NAME = 'facilities.findWorkspace';
export const FIND_WORKSPACE_ROUTE_URL = '/find-workspace';
export const PROFILE_ROUTE_NAME = 'facilities.profile';
export const PROFILE_ROUTE_URL = '/profile';
export const IDENTITY_PROVIDER_ROUTE_NAME = 'facilities.identityProvider';
export const IDENTITY_PROVIDER_ROUTE_URL = '/idp-selection';
export const CREATE_TENANT_ROUTE_NAME = 'facilities.create';
export const CREATE_TENANT_ROUTE_URL = '/create';
export const CREATE_TEAM_MEMBERS_ROUTE_NAME = 'facilities.members';
export const CREATE_TEAM_MEMBERS_ROUTE_URL = '/members';
export const REVIEW_TEAM_WORKSPACE_ROUTE_NAME = 'facilities.review';
export const REVIEW_TEAM_WORKSPACE_ROUTE_URL = '/review';
export const FACILITY_ROOT_ROUTE_NAME = 'facility';
export const FACILITY_ROOT_ROUTE_URL = '/facility/:facilityId';
export const GROUPS_ROUTE_NAME = 'facility.groups';
export const GROUPS_ROUTE_URL = '/groups';
export const GROUP_ROUTE_NAME = 'facility.group';
export const GROUP_ROUTE_URL = '/group/:groupName';
export const PAGES_ROUTE_NAME = 'facility.pages';
export const PAGES_ROUTE_URL = '/pages';
export const PAGE_ROUTE_NAME = 'facility.page';
export const PAGE_ROUTE_URL = '/pages/:pageId';
export const ADD_PAGE_ROUTE_NAME = 'facility.addPage';
export const ADD_PAGE_ROUTE_URL = '/pages/add';
export const ROUTE_TOKEN_BASIC_INFO = 'basicInfo';
export const ROUTE_TOKEN_AUTH = 'auth';

/** local storage names */
export const ROUTE_AFTER_LOGIN = 'routeAfterLogin';

/** file based */
export const FILE_TYPE_JPEG = 'image/jpeg';
export const FILE_EXTENSION_PNG = '.png';
export const FILE_EXTENSION_JPG = '.jpg';
export const BASE_64_BEGIN = 'data:';
