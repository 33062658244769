import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '@labshare/ngx-labshare-base';

@Component({
  selector: 'app-user-manage',
  template: require('./user-manage.component.html'),
  styles: [require('./user-manage.component.scss').toString()]
})
export class UserManageComponent implements OnInit {
  teamForm = this.newUserForm();
  users: Array<User> = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  newUserForm() {
    return this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
      firstName: this.fb.control(''),
      lastName: this.fb.control('')
    });
  }

  addUser() {
    const newUser: User = this.teamForm.value;
    const newUsers = [...this.users, newUser];
    this.users = newUsers;
    this.teamForm = this.newUserForm();
  }
}
