import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import {
  UtilService,
  Group,
  Tenant,
  User,
  IdentityProvider
} from '@labshare/ngx-labshare-base';
import { from, of } from 'rxjs';
import { Page } from './types';
import {
  FACILITIES_URL,
  USERS_URL,
  GROUPS_URL,
  PAGES_URL,
  REL_URL,
  PROVIDER_URL,
  LOGO_URL
} from './constants';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

/**
 * @todo implement http error handling
 */
export class FacilityService {
  constructor(private http: HttpClient, private utilService: UtilService) {}

  addFacility(facility: Tenant) {
    return this.http.post<Tenant>(
      this.utilService.getApiUrl() + FACILITIES_URL,
      facility,
      httpOptions
    );
  }

  addFacilityUser(user: User) {
    const userUrl = `${FACILITIES_URL}/${user.facilityId + USERS_URL}`;

    return this.http.post<User>(
      this.utilService.getApiUrl() + userUrl,
      user,
      httpOptions
    );
  }

  getRegularGroupsTempStorageData() {
    const groups: any[] = [
      {
        name: 'Lab Admin',
        description: 'has full edit rights and administrative privileges'
      },
      {
        name: 'Lab Staff',
        description: 'has some edit rights but no administrative privileges'
      },
      {
        name: 'Lab User',
        description: 'has some edit rights'
      },
      {
        name: 'Public',
        description: 'can only read content'
      }
    ];

    return groups;
  }

  getIdentityProviders() {
    return this.http.get<IdentityProvider>(
      this.utilService.getApiUrl() + PROVIDER_URL
    );
  }

  getRegularGroups() {
    return from(this.getRegularGroupsTempStorageData());
  }

  getGroupsForFacility(facilityId: string) {
    const url = `${FACILITIES_URL}/${facilityId + GROUPS_URL}`;

    return this.http.get<Group[]>(this.utilService.getApiUrl() + url);
  }

  getGroup(facilityId: string, groupName: string) {
    const url = `${FACILITIES_URL}/${facilityId +
      GROUPS_URL}/${encodeURIComponent(groupName)}`;

    return this.http.get<Group>(this.utilService.getApiUrl() + url);
  }

  getAdminGroup() {
    return of({
      id: 1,
      name: 'Lab Admin',
      description: 'has full edit rights and administrative privileges'
    });
  }

  getFacilitiesForUser(email: string) {
    const url = `${USERS_URL}/${email + FACILITIES_URL}`;

    return this.http.get<Tenant[]>(this.utilService.getApiUrl() + url);
  }

  getFacility(facilityId: string) {
    const url = `${FACILITIES_URL}/${facilityId}`;

    return this.http.get<Tenant>(this.utilService.getApiUrl() + url);
  }

  saveGroup(facilityId: string, group: Group, previousGroupName: string) {
    const editUrl = previousGroupName
      ? `/${encodeURIComponent(previousGroupName)}`
      : '';
    const url = `${FACILITIES_URL}/${facilityId + GROUPS_URL + editUrl}`;

    const httpRequest = previousGroupName
      ? this.http.put<Group>(
          this.utilService.getApiUrl() + url,
          group,
          httpOptions
        )
      : this.http.post<Group>(
          this.utilService.getApiUrl() + url,
          group,
          httpOptions
        );

    return httpRequest;
  }

  assignUserToGroup(facilityId: string, groupName: string, userEmail: string) {
    const url = `${FACILITIES_URL}/${facilityId +
      GROUPS_URL}/${encodeURIComponent(groupName) +
      USERS_URL +
      REL_URL}/${userEmail}`;

    return this.http.put(this.utilService.getApiUrl() + url, null, httpOptions);
  }

  getUsersForGroup(facilityId: string, groupName: string) {
    const url = `${FACILITIES_URL}/${facilityId +
      GROUPS_URL}/${encodeURIComponent(groupName) + USERS_URL}`;

    return this.http.get<User[]>(this.utilService.getApiUrl() + encodeURI(url));
  }

  deleteUser(facilityId: string, userEmail: string) {
    const url = `${FACILITIES_URL}/${facilityId + USERS_URL}/${userEmail}`;

    return this.http.delete(this.utilService.getApiUrl() + url);
  }

  getPages(facilityId: string) {
    const url = `${FACILITIES_URL}/${facilityId + PAGES_URL}`;

    return this.http.get<Page[]>(this.utilService.getApiUrl() + url);
  }

  deletePage(facilityId: string, pageId: string) {
    const url = `${FACILITIES_URL}/${facilityId + PAGES_URL}/${pageId}`;

    return this.http.delete(this.utilService.getApiUrl() + url);
  }

  getPage(facilityId: string, pageId: string) {
    const url = `${FACILITIES_URL}/${facilityId + PAGES_URL}/${pageId}`;

    return this.http.get<Page>(this.utilService.getApiUrl() + url);
  }

  savePage(facilityId: string, pageId: string, page: Page) {
    const editUrl = pageId ? `/${pageId}` : '';
    const url = `${FACILITIES_URL}/${facilityId + PAGES_URL + editUrl}`;

    const httpRequest = pageId
      ? this.http.patch<Page>(
          this.utilService.getApiUrl() + url,
          page,
          httpOptions
        )
      : this.http.post<Page>(
          this.utilService.getApiUrl() + url,
          page,
          httpOptions
        );

    return httpRequest;
  }

  saveLogo(facilityId: string, logo: File) {
    const url = `${FACILITIES_URL}/${facilityId + LOGO_URL}`;

    const formData: FormData = new FormData();
    formData.append(logo.name, logo, logo.name);

    return this.http.post(this.utilService.getApiUrl() + url, formData);
  }
}
