/** container component for facility creation screens */

import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  QueryList
} from '@angular/core';
import { UtilService } from '@labshare/ngx-labshare-base';
import { UIRouter } from '@uirouter/core';
import { WELCOME_ROUTE_NAME, ROUTE_AFTER_LOGIN } from '../constants';

@Component({
  selector: 'app-facility',
  template: require('./facilities.component.html'),
  styles: [require('./facilities.component.scss').toString()]
})
export class FacilitiesComponent implements OnInit {
  // beginning setup for theming
  private themeWrapper = document.querySelector('body');

  constructor(private utilService: UtilService, private uiRouter: UIRouter) {}

  ngOnInit() {
    this.redirectToRightPlaceAfterLogin();
  }

  redirectToRightPlaceAfterLogin() {
    const routeAfterLogin = this.utilService.getFromLocalStorage(
      ROUTE_AFTER_LOGIN
    );
    if (
      this.uiRouter.stateService.current.name === WELCOME_ROUTE_NAME &&
      routeAfterLogin
    ) {
      const location = JSON.parse(routeAfterLogin);
      this.utilService.removeFromLocalStorage(ROUTE_AFTER_LOGIN);
      this.utilService.goToRoute(location);
    }
  }

  /* this is an example for when we implement themes
  change() {
    this.themeWrapper.style.setProperty('--headerColor', 'green');
  }
  */
}
