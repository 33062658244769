import { FacilitiesComponent } from './facilities/facilities.component';
import {
  WelcomeComponent,
  ProfileComponent,
  CreateTeamMembersComponent,
  CreateTenantComponent,
  IdentityProviderSelectionComponent,
  Group,
  IdentityProvider
} from '@labshare/ngx-labshare-base';
import { FacilityHomeComponent } from './facility-home/facility-home.component';
import { ReviewTeamWorkspaceWrapperComponent } from './review-team-workspace-wrapper/review-team-workspace-wrapper.component';
import { FacilityService } from './facility.service';
import { UtilService } from '@labshare/ngx-labshare-base';
import { GroupListComponent } from './group-list/group-list.component';
import { map } from 'rxjs/operators';
import { Type } from '@angular/compiler';
import { GroupUsersComponent } from './group-users/group-users.component';
import { PagesListComponent } from './pages-list/pages-list.component';
import { PageManageComponent } from './page-manage/page-manage.component';
import { AccessWorkspaceComponent } from './access-workspace/access-workspace.component';
import { FindWorkspaceComponent } from './find-workspace/find-workspace.component';
import {
  FACILITIES_ROOT_ROUTE_URL,
  FACILITIES_ROOT_ROUTE_NAME,
  WELCOME_ROUTE_NAME,
  WELCOME_ROUTE_URL,
  ACCESS_WORKSPACE_ROUTE_URL,
  ACCESS_WORKSPACE_ROUTE_NAME,
  FIND_WORKSPACE_ROUTE_NAME,
  FIND_WORKSPACE_ROUTE_URL,
  PROFILE_ROUTE_NAME,
  PROFILE_ROUTE_URL,
  IDENTITY_PROVIDER_ROUTE_NAME,
  IDENTITY_PROVIDER_ROUTE_URL,
  CREATE_TENANT_ROUTE_URL,
  CREATE_TENANT_ROUTE_NAME,
  CREATE_TEAM_MEMBERS_ROUTE_URL,
  CREATE_TEAM_MEMBERS_ROUTE_NAME,
  REVIEW_TEAM_WORKSPACE_ROUTE_URL,
  REVIEW_TEAM_WORKSPACE_ROUTE_NAME,
  FACILITY_ROOT_ROUTE_NAME,
  FACILITY_ROOT_ROUTE_URL,
  GROUPS_ROUTE_NAME,
  GROUPS_ROUTE_URL,
  GROUP_ROUTE_NAME,
  GROUP_ROUTE_URL,
  PAGES_ROUTE_NAME,
  PAGES_ROUTE_URL,
  PAGE_ROUTE_NAME,
  PAGE_ROUTE_URL,
  ADD_PAGE_ROUTE_NAME,
  ADD_PAGE_ROUTE_URL,
  ROUTE_TOKEN_BASIC_INFO,
  ROUTE_TOKEN_AUTH
} from './constants';

export const routerConfig = {
  states: [
    {
      name: FACILITIES_ROOT_ROUTE_NAME,
      url: FACILITIES_ROOT_ROUTE_URL,
      component: FacilitiesComponent
    },
    {
      name: WELCOME_ROUTE_NAME,
      url: WELCOME_ROUTE_URL,
      component: WelcomeComponent,
      resolve: [
        {
          token: ROUTE_TOKEN_BASIC_INFO,
          resolveFn: () => ({
            newTenantUrl: PROFILE_ROUTE_NAME,
            newTenantLabel: 'Create a New Workspace',
            newTenantDescription:
              'Get your company or organization on Labshare.',
            manageTenantsUrl: ACCESS_WORKSPACE_ROUTE_NAME,
            manageTenantsLabel: 'Access your Lab Workspace',
            manageTenantsDescription: 'Join or sign in to existing workspaces.'
          })
        }
      ]
    },
    {
      name: ACCESS_WORKSPACE_ROUTE_NAME,
      url: ACCESS_WORKSPACE_ROUTE_URL,
      component: AccessWorkspaceComponent,
      resolve: [
        {
          token: ROUTE_TOKEN_AUTH,
          deps: [UtilService],
          resolveFn: utilService => {
            utilService.checkAuth();
          }
        }
      ]
    },
    {
      name: FIND_WORKSPACE_ROUTE_NAME,
      url: FIND_WORKSPACE_ROUTE_URL,
      component: FindWorkspaceComponent,
      resolve: [
        {
          token: ROUTE_TOKEN_AUTH,
          deps: [UtilService],
          resolveFn: utilService => {
            utilService.checkAuth();
          }
        }
      ]
    },
    {
      name: PROFILE_ROUTE_NAME,
      url: PROFILE_ROUTE_URL,
      component: ProfileComponent,
      resolve: [
        {
          token: ROUTE_TOKEN_BASIC_INFO,
          deps: [FacilityService],
          resolveFn: facilityService => {
            return facilityService
              .getAdminGroup()
              .pipe()
              .toPromise()
              .then((g: Group) => {
                return {
                  nextRoute: IDENTITY_PROVIDER_ROUTE_NAME,
                  previousRoute: WELCOME_ROUTE_NAME,
                  group: g.name
                };
              });
          }
        },
        {
          token: ROUTE_TOKEN_AUTH,
          deps: [UtilService],
          resolveFn: utilService => {
            utilService.checkAuth();
          }
        }
      ]
    },
    {
      name: IDENTITY_PROVIDER_ROUTE_NAME,
      url: IDENTITY_PROVIDER_ROUTE_URL,
      component: IdentityProviderSelectionComponent,
      resolve: [
        {
          token: ROUTE_TOKEN_BASIC_INFO,
          deps: [FacilityService],
          resolveFn: facilityService => {
            return facilityService
              .getIdentityProviders()
              .pipe()
              .toPromise()
              .then((identityProviders: IdentityProvider[]) => {
                return {
                  nextRoute: CREATE_TENANT_ROUTE_NAME,
                  previousRoute: PROFILE_ROUTE_NAME,
                  identityProviderList: identityProviders
                };
              });
          }
        },
        {
          token: ROUTE_TOKEN_AUTH,
          deps: [UtilService],
          resolveFn: utilService => {
            utilService.checkAuth();
          }
        }
      ]
    },
    {
      name: CREATE_TENANT_ROUTE_NAME,
      url: CREATE_TENANT_ROUTE_URL,
      component: CreateTenantComponent,
      resolve: [
        {
          token: ROUTE_TOKEN_BASIC_INFO,
          resolveFn: () => ({
            nextRoute: CREATE_TEAM_MEMBERS_ROUTE_NAME,
            previousRoute: IDENTITY_PROVIDER_ROUTE_NAME,
            tenantNameFormLabel: 'Team Name',
            tenantTagFormLabel: 'Unique Tag'
          })
        },
        {
          token: ROUTE_TOKEN_AUTH,
          deps: [UtilService],
          resolveFn: utilService => {
            utilService.checkAuth();
          }
        }
      ]
    },
    {
      name: CREATE_TEAM_MEMBERS_ROUTE_NAME,
      url: CREATE_TEAM_MEMBERS_ROUTE_URL,
      component: CreateTeamMembersComponent,
      resolve: [
        {
          token: ROUTE_TOKEN_BASIC_INFO,
          resolveFn: () => ({
            nextRoute: REVIEW_TEAM_WORKSPACE_ROUTE_NAME,
            previousRoute: CREATE_TENANT_ROUTE_NAME
          })
        },
        {
          token: 'groupList',
          deps: [FacilityService],
          resolveFn: facilityService => {
            const groups: Group[] = [];
            return facilityService
              .getRegularGroups()
              .pipe(
                map(g => {
                  groups.push(g as Group);
                })
              )
              .toPromise()
              .then(() => {
                return groups;
              });
          }
        },
        {
          token: ROUTE_TOKEN_AUTH,
          deps: [UtilService],
          resolveFn: utilService => {
            utilService.checkAuth();
          }
        }
      ]
    },
    {
      name: REVIEW_TEAM_WORKSPACE_ROUTE_NAME,
      url: REVIEW_TEAM_WORKSPACE_ROUTE_URL,
      component: ReviewTeamWorkspaceWrapperComponent,
      resolve: [
        {
          token: ROUTE_TOKEN_AUTH,
          deps: [UtilService],
          resolveFn: utilService => {
            utilService.checkAuth();
          }
        }
      ]
    },
    {
      name: FACILITY_ROOT_ROUTE_NAME,
      url: FACILITY_ROOT_ROUTE_URL,
      component: FacilityHomeComponent
    },
    {
      name: GROUPS_ROUTE_NAME,
      url: GROUPS_ROUTE_URL,
      component: GroupListComponent
    },
    {
      name: GROUP_ROUTE_NAME,
      url: GROUP_ROUTE_URL,
      component: GroupUsersComponent
    },
    {
      name: PAGES_ROUTE_NAME,
      url: PAGES_ROUTE_URL,
      component: PagesListComponent
    },
    {
      name: PAGE_ROUTE_NAME,
      url: PAGE_ROUTE_URL,
      component: PageManageComponent
    },
    {
      name: ADD_PAGE_ROUTE_NAME,
      url: ADD_PAGE_ROUTE_URL,
      component: PageManageComponent
    }
  ],
  useHash: false
};
