import { Component, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Tenant, Group } from '@labshare/ngx-labshare-base';
import { FacilityService } from '../facility.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GroupManageComponent } from '../group-manage/group-manage.component';
import { mergeMap, tap } from 'rxjs/operators';
import { of, from } from 'rxjs';

@Component({
  selector: 'app-group-list',
  template: require('./group-list.component.html'),
  styles: [require('./group-list.component.scss').toString()]
})
export class GroupListComponent implements OnInit {
  facility: Tenant = {
    id: '',
    title: ''
  };
  groups: any[] = [];
  counter = 0;
  closeResult: string;
  modalRef: NgbModalRef;

  constructor(
    private uiRouter: UIRouter,
    private facilityService: FacilityService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.getGroups();

    this.facilityService
      .getFacility(this.uiRouter.stateService.params.facilityId)
      .subscribe(data => {
        this.facility = data;
      });
  }

  open() {
    this.modalRef = this.modalService.open(GroupManageComponent);
    this.modalRef.componentInstance.facilityId = this.uiRouter.stateService.params.facilityId;
    this.modalRef.result.then(
      () => {
        this.getGroups();
      },
      reason => {
        this.getGroups();
      }
    );
  }

  getGroups() {
    this.groups = [];
    const addCustomGroup: Group = {
      id: 0,
      name: 'Custom Group',
      description: ''
    };
    this.facilityService
      .getGroupsForFacility(this.uiRouter.stateService.params.facilityId)
      .pipe(
        mergeMap(groups => {
          this.groups = groups;
          this.groups = [...this.groups, addCustomGroup];
          return from(this.groups).pipe(
            mergeMap(group => {
              if (group.name === 'Custom Group') {
                return of(null);
              }
              return this.facilityService
                .getUsersForGroup(
                  this.uiRouter.stateService.params.facilityId,
                  group.name
                )
                .pipe(
                  tap(results => {
                    group.userCount = results.length;
                  })
                );
            })
          );
        })
      )
      .subscribe();
  }
}
