import {
  Component,
  OnInit,
  forwardRef,
  ChangeDetectorRef,
  Optional,
  SkipSelf,
  ApplicationRef
} from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { UtilService, Tenant } from '@labshare/ngx-labshare-base';
import { FacilityService } from '../facility.service';
import {
  StatefulParent,
  StatefulService,
  StatefulComponent
} from '@labshare/ngx-stateful';
import { flatMap, map, switchMap, tap } from 'rxjs/operators';

/* Export NgxStateful's state, for when lifting becomes necessary */
export const STATE = () => ({
  items: [],
  selectedSection: null,
  usingStorage: false,
  displaySelectedSection: true,
  preserveSection: true,
  preserveMenu: 0,
  menuWidth: 300,
  menuItems: [
    {name: 'Groups', link: 'groups', icon: 'puzzle-piece'},
    {name: 'Pages', link: 'pages', icon: 'user'},
    {name: 'Ls Storage', link: 'storage', icon: 'users'}
  ],
  storageItems: [
    {
      name: 'Space 1',
      id: 's1',
      link: null,
      icon: 'http://placekitten.com/g/200/300'
    },
    {
      name: 'Space 2',
      id: 's2',
      link: null,
      icon: 'http://placekitten.com/g/200/300'
    },
    {
      name: 'Space 3',
      id: 's3',
      link: null,
      icon: 'http://placekitten.com/g/200/300'
    },
    {
      name: 'Space 4',
      id: 's4',
      link: null,
      icon: 'http://placekitten.com/g/200/300'
    }
  ],
  currentMenuItem: null
});

const SELECTED_SECTION = 'selectedSection';
const FACILITY_GROUPS = 'facility.groups';
const ITEMS = 'items';
const USING_STORAGE = 'usingStorage';
const DISPLAY_SELECTED_SECTION = 'displaySelectedSection';
const MENU_WIDTH = 'menuWidth';
const MENU_ITEMS = 'menuItems';
const CURRENT_ITEM = 'currentMenuItem';

@Component({
  selector: 'app-facility-home',
  template: require('./facility-home.component.html'),
  styles: [require('./facility-home.component.scss').toString()],

  /* Provide this component as StatefulParent to Stateful children */
  providers: [
    {
      provide: StatefulParent,
      useExisting: forwardRef(() => FacilityHomeComponent)
    }
  ]
})
export class FacilityHomeComponent extends StatefulComponent implements OnInit {
  constructor(
    private utilService: UtilService,
    private uiRouter: UIRouter,
    private facilityService: FacilityService,
    /* NgxStateful Providers */
    inj: ChangeDetectorRef,
    @Optional() @SkipSelf() public statefulParent: StatefulParent,
    public statefulService: StatefulService,
    public appRef: ApplicationRef
  ) {
    /* Instantiate NgxStateful Component */
    super(inj, STATE, statefulParent, statefulService, appRef);
  }
  facilities: Tenant[] = [];

  /* TODO: Improve once prev and curr args are available */
  lastSelectedSection = null;
  lastCurrentItem = null;

  /* Avoid using ngOnInit in NgxStateful Components, will overwrite internal methods */
  onStatefulInit() {
    this.state.set(
      SELECTED_SECTION,
      this.uiRouter.stateService.params.facilityId
    );
    const subUrl = this.uiRouter.stateService.current.url.split('/')[1];

    this.state.set('currentMenuItem', subUrl);

    if (this.uiRouter.stateService.params['facilityId'] === 'storage') {
      /* TODO: Use PropsSlice when released */
      this.state.batch(({set}) => {
        set(MENU_ITEMS, []);
        set(USING_STORAGE, true);
        set(DISPLAY_SELECTED_SECTION, false);
        set(MENU_WIDTH, 380);
      });
    }

    this.facilityService
      .getFacilitiesForUser(this.utilService.getLoggedInUserProfile().email)
      .pipe(
        map(arr =>
          arr.map(item => ({
            name: item.title,
            id: item.id,
            link: null,
            icon: 'http://placekitten.com/g/50/50'
          }))),
          map(arr => [...arr, {
            name: 'Storage',
            id: 'storage',
            link: null,
            icon: 'http://placekitten.com/g/50/50'
          }])
      )
      .subscribe(data => {
        this.state.set(ITEMS, data);
      });
  }
  /* Whenever selectedSection changes, trigger uiRouter */
  onStatefulChanges() {
    const currentSection = this.state.read(SELECTED_SECTION);
    if (currentSection !== this.lastSelectedSection) {
      if (this.uiRouter.stateService.params.facilityId !== currentSection) {
        this.uiRouter.stateService.go(FACILITY_GROUPS, {
          facilityId: currentSection
        });
        this.lastSelectedSection = currentSection;
      }
    }

    const currentItem = this.state.read(CURRENT_ITEM);
    if (currentItem !== this.lastCurrentItem) {
      if (this.uiRouter.stateService.current.url.split('/')[1] !== currentItem) {
        this.uiRouter.stateService.go('facility.' + currentItem, {
          facilityId: currentSection
        });
        this.lastCurrentItem = currentItem;
      }

    }
  }
}
